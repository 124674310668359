<template>
  <div>
    <div class="column-title" v-if="teacherInfo.isShowTitle">
       {{ teacherInfo.title }}
    </div>
    <!--小图横向-->
    <div class="pic-more">
      <div class="pic-more-list">
        <div class="pic-item" v-for="(item, index) in teacherInfo.teacherList" :key="index">
          <el-image
            :src="item.url"
            class="pic-class"
          ></el-image>
          <div>{{ item.teacherName }}</div>
        </div>
      </div>
      <div v-if="teacherInfo.teacherList.length===0" class="tac">请在右侧【组件设置】中添加教师</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    teacherInfo: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
   
   
   .pic-more {
    margin:16px;
    width: 358px;
    height: 119px;
    border-radius: 10px;
    filter: drop-shadow(0 0 12.5px rgba(153,153,153,0.25));
    background-color: #ffffff;
    overflow: hidden;
    .pic-more-list {
      display: flex;
      overflow: auto;
      padding:16px;
       .pic-item {
         display: flex;
         flex-direction: column;
         justify-content: space-around;
         align-items: center;
         padding-right:16px;
         height:90px;
       }
       .pic-class {
          width:60px;
          height:60px;
          border-radius: 50%;
          background-color:#ddd;
          //border:1px solid #ddd;
          filter: drop-shadow(0 0 1.5px rgba(153,153,153,0.25));
       }
    }
  //   .pic-more-list::-webkit-scrollbar {
  //   width: 0 !important;
  // }
  // .pic-more-list::-webkit-scrollbar-track {
  //   width: 0 !important;
  // }
   }
</style>