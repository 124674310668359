<template>
   <div>
    <div
      class="column-title"
      v-if="pictureInfo.isShowTitle && pictureInfo.pictureLayout !== 1"
    >
      {{ pictureInfo.title }}
    </div>
    <!--顶部轮播图-->
    <div
     :class="pictureInfo.pictureLayout === 2?'mt16':''"
      v-if="
        (pictureInfo.pictureLayout === 1 || pictureInfo.pictureLayout === 2) &&
          pictureInfo.pictureList.length > 0
      "
    >
      <el-carousel height="210px">
        <el-carousel-item
          v-for="(item, index) in pictureInfo.pictureList"
          :key="index"
        >
          <div class="small" @click="handlePicShow(item)">
            <el-image
              style="width:100%"
              :src="item.url"
              fit="contain"
            ></el-image>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!--纵向排列-->
    <template v-if="pictureInfo.pictureLayout === 3">
      <div
        class="big-pic-vertical"
        v-for="(item, index) in pictureInfo.pictureList"
        :key="index"
        @click="handlePicShow(item)"
      >
        <el-image :src="item.url" fit="contain"></el-image>
        <div class="pic-name">
          {{ item.showName }}
        </div>
      </div>
    </template>
    <!--小图横向-->
    <template v-if="pictureInfo.pictureLayout === 4">
      <div class="small-pic-horizontal">
        <div
          class="pic-item"
          v-for="(item, index) in pictureInfo.pictureList"
          :key="index"
          @click="handlePicShow(item)"
        >
          <el-image fit="contain" :src="item.url" > </el-image>
          <div class="pic-name">
            {{ item.showName }}
          </div>
        </div>
      </div>
    </template>
    <div class="pd30 tac red" v-if="pictureInfo.pictureList.length === 0">
      请在右侧【组件设置】中上传图片信息！
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pictureInfo: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  mounted () {
  },
  methods: {
    handlePicShow(item){
      const targetLink = item.targetLink
      if(targetLink){
         // 有跳转链接
         //判断是微信或微信端浏览器
         this.$emit('handleHref',targetLink)
      }
    },
  }
}
</script>
<style lang="less">
.el-carousel__button {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
</style>

<style lang="less" scoped>
/**
*  小图横向展示
 */
.small-pic-horizontal {
  padding: 16px 16px 0;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .pic-item {
    position: relative;
    width: 170px;
    height: 110px;
    margin-bottom: 16px;
    border-radius: 16px;
    overflow: hidden;
    .el-image {
      display: block;
    }
    .pic-name {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding:3px;
       font-size: 14px;
      text-align: center;
      color: #fff;
      background-color: rgba(0,0,0,0.6);
    }
  }
}
/**
*  大图纵向展示
 */
.big-pic-vertical {
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  width: 358px;
  margin: 16px;
  .el-image {
    display: block;
  }
  .pic-name {
    position: absolute;
    left: 0;
    bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    text-align: center;
    height: 32px;
    line-height: 32px;
    font-size: 16px;
    color: #fff;
    background-color: rgba(0,0,0,0.6);
  }
}

.pic-more-list::-webkit-scrollbar {
    width: 0 !important;
  }
  .pic-more-list::-webkit-scrollbar-track {
    width: 0 !important;
  }
</style>
