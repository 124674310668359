<template>
  <div v-if="componentList">
    <div
      v-for="(item, index) in componentList"
      :key="item.componentId + index + ''"
    >
      <template v-if="item.componentId === '视频'">
        <Video :videoInfo.sync="item.videoInfo"></Video>
      </template>
      <template v-if="item.componentId === '图片广告'">
        <Picture
          :pictureInfo.sync="item.pictureInfo"
          @handleHref="handleHref"
        ></Picture>
      </template>
      <template v-if="item.componentId === '教师风采'">
        <Teacher :teacherInfo.sync="item.teacherInfo"></Teacher>
      </template>
      <template v-if="item.componentId === '品牌介绍'">
        <Brand
          :brandInfo.sync="item.brandInfo"
          @handleHref="goSchoolDetails"
        ></Brand>
      </template>
      <template v-if="item.componentId === '机构信息'">
        <Organization :eduorgInfo.sync="item.eduorgInfo"></Organization>
      </template>
    </div>
  </div>
</template>
<script>
//微信公众开台的appID，不是机构小程序的appID

import { getSiteInfo, getSign } from "@/api/site";
import Picture from "./components/picture";
import Video from "./components/video";
import Brand from "./components/brand";
import Teacher from "./components/teacher";
import Organization from "./components/organization";
import wx from "weixin-js-sdk";
import { isMiniprogram } from "@/utils/index";
import { appId, h5Url } from "@/config/sys-config";
export default {
  components: {
    Organization,
    Picture,
    Video,
    Brand,
    Teacher,
  },
  data() {
    return {
      title:'小程序名称',
      componentList: [],
      siteInfo: null,
      teacherInfo: null,
      websiteSetting: null,
    };
  },
  mounted() {
    //配置微信接口
    this.setWxConfig();
    this.getSiteInfo();
  },
  methods: {
    //学校详情
    goSchoolDetails() {
      var tcode = this.$route.query.tcode;
      isMiniprogram((res) => {
        if (res) {
          wx.miniProgram.navigateTo({
            url: `/pages/webview/index?weburl=${h5Url}/school-detail&tcode=${tcode}`,
          });
        } else {
          this.$router.push({
            path: "/school-detail",
            query: {
              tcode,
            },
          });
        }
      });
    },
    // 获取签名信息
    setWxConfig() {
      const url = location.href.split("#")[0];
      getSign({ url }).then((res) => {
        if (res && res.code === 200) {
          const data = res.data;
          // 设置weixin-js-sdk接口配置
          wx.config({
            debug: false, //是否开启调试功能，这里关闭！
            appId, //appid
            timestamp: data.timestamp, //时间戳
            nonceStr: data.nonceStr, //生成签名的随机字符串
            signature: data.signature, //签名
            jsApiList: [
              "updateTimelineShareData",
              "updateAppMessageShareData"
            ],
            openTagList:['wx-open-launch-weapp']
          });
        }
      });
    },

    /**
     * @param {jssdk为从后台请求过来的各种信息,包括appId,timestamp,nonceStr,signature} jssdk
     * 以上4个参数，需要后台在公众号相关平台进行配置，然后得出！前端页面必须放在服务号配置的域名下面才可以保证成功！
     * @param {为js对象格式,options里面包含自定义的title,desc,link,imgUrl} options
     * 引入jweixin文件后,直接调用该函数，即可实现自定义分享功能
     */
    // 自定义分享
    selfShare() {
      const shareSetting = this.websiteSetting.shareSetting;
      const imgUrl = shareSetting.previewImageUrl;
      const desc = shareSetting.description;
      const link = location.href.split("#")[0];
      const title = shareSetting.title;
      var defaults = {
        title,
        desc,
        type: "link",
        link, //分享页面地址,不能为空，这里可以传递参数！！！！！！！
        imgUrl, //分享是封面图片，不能为空
        success: function () {
          wx.showToast({
            title: "分享成功！",
          });
        }, //分享成功触发
        cancel: function () {
          wx.showToast({
            title: "分享失败！",
          });
        }, //分享取消触发，需要时可以调用
      };
      wx.ready(function () {
        // 分享到朋友圈
        wx.updateTimelineShareData(defaults);
        // 分享给朋友
        wx.updateAppMessageShareData(defaults);
      });
    },
    // 处理页面跳转
    handleHref(url) {
      //判断是否是微信小程序
      isMiniprogram((res) => {
        if (res) {
          //处理来自图片链接，微官网与自定义的链接有‘？’
          url = url.replace("?", "&");
          wx.miniProgram.navigateTo({
            url: `/pages/webview/index?weburl=${url}`,
          });
        } else {
          window.open(url, "_blank");
        }
      });
    },
    // 获取官网信息
    getSiteInfo() {
      const tcode = this.$route.query.tcode;
      getSiteInfo({ tcode }).then((res) => {
        if (res && res.code === 200) {
          // 代码转义
          let data = JSON.parse(res.data.content);
          const websiteSetting = data.websiteSetting;

          // 设置页面标题
          document.title = websiteSetting.pageSetting.websiteName;
          // 官网设置
          this.websiteSetting = websiteSetting;
          // 官网信息
          this.componentList = data.componentList;
          this.selfShare();
        }
      });
    },
  },
};
</script>
<style lang="less">
  .tttt {
     width:200px;
     height:10px;
  }
</style>
