<template>
  <div>
    <div class="brand-title">
       <div class="column-title">
         <template  v-if="brandInfo.isShowTitle">
            {{ brandInfo.title }}
         </template>
    </div>
      <div class="school-instruction" @click="showSchool()">
        学校介绍 <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="brand-show">
       <div class="brand-item" v-for="(item,index) in albumList" :key="index">
          <el-image :src="item.url" fit="cover" :preview-src-list="item.itemList">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <div class="pic-count">{{ item.itemList.length }} 张</div>
          <div class="pic-name">
            {{ item.albumName || '相册名称' }}
          </div>
       </div>
    </div>
    <!-- <div v-if="albumList.length===1" class="medium adjustStyle">
       <el-image :src="albumList[0].url" fit="contain" width="100%" :preview-src-list="albumList[0].itemList">
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
      <div class="pic-count">共 {{ albumList[0].itemList.length }} 张</div>
      <div class="pic-name">
        {{ albumList[0].albumName || '相册名称' }}
      </div>
    </div>
    <template v-else>
      <el-carousel :interval="4000" type="card" height="150px">
      <el-carousel-item v-for="(item,index) in albumList" :key="index">
        <div class="medium">
          <el-image :src="item.url" fit="contain" :preview-src-list="brandInfo.albumList[index].itemList">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <div class="pic-count">共 {{ item.itemList.length }} 张</div>
          <div class="pic-name">
            {{ item.albumName || '相册名称' }}
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    </template>
    
    <div class="brand-line">查看学校介绍</div> -->
  </div>
</template>

<script>
export default {
  props: {
    brandInfo: {
      type: Object,
      required: true
    }
  },
  computed:{
    albumList(){
       // 优化图片预览
       const albumList = this.brandInfo.albumList.map(item=>{
          if(item.itemList.length>0){
             item.itemList = item.itemList.map(sItem =>sItem.url)
          }
          return item;
       })

       return albumList;
    }
  },
  data () {
    return {}
  },
  methods: {
    showSchool(){
      this.$emit('handleHref')
    }
  }
}
</script>

<style lang="less" scoped>
.brand-line {
  text-align: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.brand-title {
  width:374px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .title-catname {
    font-weight: 600;
  }
  .school-instruction {
    font-size: 12rpx;
  }
}
.brand-show {
   margin:16px;
   overflow: auto;
   display: -webkit-box;
   .brand-item {
      position: relative;
      width:140px;
      height:204px;
      overflow: hidden;
      border-radius: 16px;
      margin-right:16px;
      // box-shadow: 1px 2px 1px #ccc;
      .el-image {
         display: block;
         width:100%;
         height: 100%;
      }
      .pic-count {
        position: absolute;
        top:0;
        right:0;
        padding:5px;
        border-radius: 6px;
         font-size:12px;
        background-color:rgba(255, 255, 255, 0.89);
        color:rgb(55, 84, 248);
      }
      .pic-name {
         max-width: calc(100% - 20px);
         position: absolute;
         left:10px;
         bottom:10px;
         background-color: rgba(255, 255, 255, 0.6);
         border-radius: 16px;
         color:#333;
         padding:5px 10px;
         font-size:12px;
         border:1px solid #eee;
         white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
   }
}

  .brand-show::-webkit-scrollbar {
    width: 0 !important;
  }
  .brand-show::-webkit-scrollbar-track {
    width: 0 !important;
  }
</style>
