<template>
  <div>
    <div class="column-title" v-if="videoInfo.isShowTitle">
      {{ videoInfo.title || '这是标题' }}
    </div>
    <!--单个视频-->
    <template v-if="videoInfo.videoLayout === 1">
      <div class="video-show" v-if="videoInfo.videoList.length > 0" @click="playVideo(videoInfo.videoList[0].url)">
        <el-image
          fit="none"
          :src="
            videoInfo.videoList[0].url +
              '?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,m_fast'
          "
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <div class="icon_play_box">
          <div class="icon_play"></div>
        </div>
        <!-- <i class="iconfont eplay-video"></i> -->
        <div class="video-name">{{ videoInfo.videoList[0].showName }}</div>
      </div>
    </template>

    <template v-if="videoInfo.videoLayout === 2 || videoInfo.videoLayout === 3">
      <!--两个视频-->
      <!--多个视频-->
      <div class="video-three" v-if="videoInfo.videoList.length > 0">
        <div class="video-three-list">
          <div
            class="video-item"
            v-for="(item, index) in videoInfo.videoList"
            :key="index"
            @click="playVideo(item.url)"
          >
            <el-image
              :src="
                item.url +
                  '?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,w_0,h_0,m_fast,ar_auto'
              "
              fit="none"
            ></el-image>
            <div class="icon_play_box">
          <div class="icon_play"></div>
        </div>
            <div class="video-foot">
              <div class="foot-left">{{ item.showName }}</div>
            </div>
          </div>
           <div class="video-add" v-if="videoInfo.videoList.length ===1">
               +
           </div>
        </div>
      </div>
    </template>
    <div v-show="isPlayVideo">
        <i class="el-icon-arrow-left" @click="closeVideo"></i>
        <video
        preload="auto"
        id="home_video"
        :src="videoUrl"
        controls="controls"
        class="video-play"
      ></video>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    videoInfo: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
       isPlayVideo: false,
       videoUrl:null
    }
  },
  mounted() {
    console.log(this.videoInfo,'video')
  },
  methods: {
    playVideo(url) {
      let that = this;
      that.isPlayVideo = true;
      this.videoUrl = url
      console.log(this.videoUrl)
      var vide = document.getElementById("home_video");
      if (vide.paused) {
        vide.play();
      } else if (vide.play()) {
        vide.pause();
      }
    },
    //关闭视频
    closeVideo() {
        var vide = document.getElementById("home_video");
        vide.pause();
        this.isPlayVideo = false
    },
  }
}
</script>

<style lang="less" scoped>
 .icon_play_box{
    position: absolute;
    top:50%;
    left:50%;
    margin-top:-20px;
     margin-left:-10px;
    width:20px;
    height:20px;
    line-height: 20px;
    border:1px solid #fff;
    padding:10px;
    border-radius: 50%;
    .icon_play {
      margin-left:4px;
      margin-top:1px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 0 10px 18px;
      border-color: transparent transparent transparent #fff;

    }
  }
.video-cover {
  width: 343px;
  height: 162px;
}
.mb16 {
  margin-bottom: 16px;
}
.video-show {
  position: relative;
  margin: 16px;
  overflow: hidden;
  width: 358px;
  height: 231px;
  border-radius: 10px;
  .el-image {
    display: block;
  }

  .video-name {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 32px;
    line-height: 32px;
    padding-left: 10px;
    font-size: 16px;
    text-align: left;
    background-color: rgba(0,0,0, 0.5);
    color: #fff;
  }
}
.video-three {
  overflow: hidden;
  .video-three-list {
    width:100%;
    display: -webkit-box;
    flex-wrap: nowrap;
    .video-item {
      position: relative;
      margin: 16px;
      width: 258px;
      height:182px;
      border-radius: 10px;
      text-align: center;
      overflow: hidden;
      .el-image{
        display: block;
      }
      i {
        font-size: 40px;
      }
    }
    .video-add {
      margin:16px 0;
      font-size:80px;
      color:#ccc;
      border-radius: 10px;
      text-align: center;
      width:83px;
      line-height: 182px;
      background-color: #eee;
    }
  }
  .video-three-list::-webkit-scrollbar {
    width: 0 !important;
  }
  .video-three-list::-webkit-scrollbar-track {
    width: 0 !important;
  }
}
.video-catname {
  font-weight: 700;
  padding: 10px;
}
.video-foot {
  justify-content: space-between;
  align-items: center;
  background-color:rgba(0,0,0, 0.6);
  height: 30px;
  line-height: 30px;
  font-size:14px;
  // padding: 5px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #fff;
  overflow: hidden;
  .foot-left {
    width: 258px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.el-icon-arrow-left {
   color:#fff;
     position: fixed;
    top: 10px;
    left: 10px;
    font-size:36px;
    z-index:10;

}
.video-play {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index:9;
    background: #000;
  }
</style>
