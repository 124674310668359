<template>
  <div v-if="eduorgInfo">
    <div class="column-title">地图地址</div>
     <div class="eduorgInfo">
        <div id="mapShow" class="mapShow" v-if="eduorgInfo.pathLabels && eduorgInfo.address" style="width:329px;height:200px;"></div>
        <strong>{{ eduorgInfo.name || '这里是机构名称' }}</strong>
        <p>
           <strong>地址：</strong>
           <span>{{eduorgInfo.pathLabels }}{{ eduorgInfo.address  }} </span>
        </p>
     </div>
  </div>
 
</template>

<script>
let geocoderShow = null
let markersArrayShow = []
export default {
  props: {
    eduorgInfo: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  watch: {
    eduorgInfo: {
      handler: function () {
        this.handleGeocoder()
      },
      // 开启深度监听：只要obj中的任何一个属性发生改变，都会触发相应的代码
      deep: true
    }
  },
  mounted () {
    this.initMap()
    this.handleGeocoder()
  },
  methods: {
    handleGeocoder () {
      const pathLabels = this.eduorgInfo.pathLabels
      const address = this.eduorgInfo.address
      const keyword = pathLabels + address
      console.log(111)
      if (pathLabels && address) {
        geocoderShow.getLocation(keyword)
      }
    },
     /* eslint-disable */ 
    initMap () {
      const latitude = this.eduorgInfo.latitude
      const longitude = this.eduorgInfo.longitude
     // 定义map变量 调用 qq.maps.Map() 构造函数   获取地图显示容器
      var map = new qq.maps.Map(document.getElementById('mapShow'), {
        center: new qq.maps.LatLng(latitude, longitude),      // 地图的中心地理坐标。
        zoom: 8                                                // 地图的中心地理坐标。
      })
      geocoderShow = new qq.maps.Geocoder({
        complete: function (result) {
          console.log(result)
          // that.dataForm.longitude = result.detail.location.lng
          // that.dataForm.latitude = result.detail.location.lat
          map.setCenter(result.detail.location)
          map.setZoom(14)
          var marker = new qq.maps.Marker({
            map: map,
            position: result.detail.location
          })
          markersArrayShow.push(marker)
        }
      })
    }
     /* eslint-disable */ 
  }
}
</script>

<style lang="less" scoped>

.eduorgInfo {  
  margin:16px;
  padding:16px;
  width:326px;
  background-color:#fafafa;
  border-radius: 10px;
   strong {
      font-size: 16px;
   }
   span {
      color:#999;
   }
   p {
     margin-bottom:0px;
   }
}
.mapShow {
  border-radius: 10px;
  margin-bottom:20px;
}
</style>
